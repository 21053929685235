<template>
  <div class="text-center">
    <v-dialog v-model="localDialog" width="auto">
      <v-card>
        <HoldingDialogEdit />
        <HoldingDialogList :shareTransactions="portfolioDataStore" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HoldingDialogEdit from "@/components/portfolio/HoldingDialog/HoldingDialogContent/HoldingDialogEdit.vue";
import HoldingDialogList from "@/components/portfolio/HoldingDialog/HoldingDialogContent/HoldingDialogList.vue";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";

export default {
  data() {
    return {
      shareTransactions: {},
    };
  },
  setup() {
    const portfolioDataStore = usePortfolioDataStore();
    return {
      portfolioDataStore,
    };
  },

  components: {
    HoldingDialogEdit,
    HoldingDialogList,
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          // Only emit close event when dialog is being closed
          this.$emit("close-dialog");
        }
      },
    },
  },
};
</script>
