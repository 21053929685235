<template>
  <div>
    <PortfolioTabs @tab-changed="handleTabChange" />
    <PortfolioTable
      :shares="shares"
      :columns="currentColumns"
      @holding-row-clicked="openDialog"
    />
  </div>
  <div>
    <HoldingDialog :dialog="dialogState" @close-dialog="dialogState = false" />
  </div>
</template>

<script>
import PortfolioTabs from "@/components/portfolio/PortfolioTabs.vue";
import PortfolioTable from "@/components/portfolio/PortfolioTable.vue";
import HoldingDialog from "@/components/portfolio/HoldingDialog/HoldingDialog.vue";

export default {
  components: {
    PortfolioTabs,
    PortfolioTable,
    HoldingDialog,
  },
  data() {
    return {
      shares: [], // Placeholder for your shares data
      allColumns: {
        Holdings: [
          { header: "", field: "logo", editor: false, width: "5%" },
          { header: "Ticker", field: "ticker", width: "5%" },
          { header: "Name", field: "name", width: "10%" },
          { header: "Price", field: "current_price", width: "10%" },
          { header: "Position", field: "total_position", width: "10%" },
          {
            header: "Invested",
            field: "total_cost",
            width: "10%",
            prefix: "$",
          },
          {
            header: "Current result",
            field: "current_result",
            width: "10%",
            prefix: "$",
          },
        ],
        Financials: [
          { header: "", field: "logo", editor: false, width: "5%" },
          { header: "Ticker", field: "ticker", width: "5%" },
          { header: "Name", field: "name", width: "10%" },
          { header: "Revenue", field: "revenue", width: "10%" },
          { header: "Profit", field: "profit", width: "10%" },
          { header: "Fair Value", field: "fv", width: "10%" },

          // ... other columns specific to the Financials tab
        ],
        Risk: [
          { header: "Risk Level", field: "riskLevel", width: "10%" },
          { header: "Volatility", field: "volatility", width: "10%" },
          // ... other columns specific to the Risk tab
        ],
        Insights: [
          { header: "Insight", field: "insight", width: "20%" },
          { header: "Date", field: "date", width: "10%" },
          // ... other columns specific to the Insights tab
        ],
      },
      activeTab: "Holdings", // Default tab
      dialogState: false,
    };
  },
  computed: {
    currentColumns() {
      return this.allColumns[this.activeTab];
    },
  },
  methods: {
    openDialog() {
      this.dialogState = true;
    },
    handleTabChange(tabName) {
      this.activeTab = tabName;
      console.log("tab change");
      // Any additional logic to fetch new data or update columns can be added here
    },
  },
};
</script>
