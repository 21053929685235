import axios from 'axios';


export async function getShareByTicker(shareTicker) {
  try {
  
    const response = await axios.get(`https://hasura-beestock-dev.haslle.com/api/rest/get_share_by_ticker/${shareTicker}`,
    {
      headers: {
        "x-hasura-admin-secret":
        "ptNFD2FCJpa9LLhRziH1mFshA38HNamuAKgZ6DgQRhgPmL8BdkYSc7fV7TSw72hP",
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data:', error);
    throw error;
  }
}

export async function setUpNewUser(data, telegram_username) {
  try {
      console.log("telegram: ",telegram_username)
      const status = "onboarding"
    const response = await axios.post(`https://hasura-beestock-dev.haslle.com/api/rest/user_setup/${telegram_username}/${status}/${data.email}`, {},
    {
      headers: {
        "x-hasura-admin-secret":
        "ptNFD2FCJpa9LLhRziH1mFshA38HNamuAKgZ6DgQRhgPmL8BdkYSc7fV7TSw72hP",
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data:', error);
    throw error;
  }
}

export async function getTransactionsByShare(share_ticker, user_id) {
  try {
  
    const response = await axios.get(`https://hasura-beestock-dev.haslle.com/api/rest/share_transactions/${user_id}/${share_ticker}`,
    {
      headers: {
        "x-hasura-admin-secret":
        "ptNFD2FCJpa9LLhRziH1mFshA38HNamuAKgZ6DgQRhgPmL8BdkYSc7fV7TSw72hP",
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error getting transactions for share:', error);
    throw error;
  }
}

export async function getPortfolio(idToken) {
  try {
  
    const response = await axios.get(`https://beestock-yoda-dev.haslle.com/portfolio/overview`,
    {
      headers: {
        Authorization: idToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data to fetch portfolio:', error);
    throw error;
  }
}

export async function getTrackers(idToken) {
  try {
  
    const response = await axios.get(`https://beestock-yoda-dev.haslle.com/trackers/overview`,
    {
      headers: {
        Authorization: idToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data to fetch trackers:', error);
    throw error;
  }
}

export async function getPortfolioTransactions(idToken, ticker) {
  
  try {
  
    const response = await axios.get(`https://beestock-yoda-dev.haslle.com/portfolio/${ticker}`,
    {
      headers: {
        Authorization: idToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data to fetch portfolio:', error);
    throw error;
  }
}

export async function getUserData(idToken) {
  try {
  
    const response = await axios.get(`https://beestock-yoda-dev.haslle.com/users/`,
    {
      headers: {
        Authorization: idToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data to fetch UserData:', error);
    throw error;
  }
}

export async function getSharesListSearch(idToken, ticker) {
  try {
    const requestBody = {
      search: ticker,
      limit: 10,
      offset: 0,
    };

    const response = await axios.post('https://beestock-yoda-dev.haslle.com/shares/filter', requestBody, {
      headers: {
        Authorization: idToken,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error submitting data to fetch SharesSearch:', error);
    throw error;
  }
}

export async function getShareFinanceData(idToken, ticker) {

  try {
  
    const response = await axios.get(`https://beestock-yoda-dev.haslle.com/shares/${ticker}/kpis`,
    {
      headers: {
        Authorization: idToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data to fetch share all kpi:', error);
    throw error;
  }
}

export async function getShareInfo(idToken, ticker) {

  try {
  
    const response = await axios.get(`https://beestock-yoda-dev.haslle.com/shares/${ticker}`,
    {
      headers: {
        Authorization: idToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data to fetch share info:', error);
    throw error;
  }
}

export async function addNewTracker(idToken, ticker, kpi_id, threshold_max, threshold_min) {
  try {
    const requestBody = {
      share_ticker: ticker,
      kpi_id: kpi_id,
      threshold_max: threshold_max,
      threshold_min: threshold_min,
    };
console.log("trying to add:",idToken, ticker, kpi_id, threshold_max, threshold_min)
    const response = await axios.post('https://beestock-yoda-dev.haslle.com/trackers/', requestBody, {
      headers: {
        Authorization: idToken,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error submitting data to add a new Tracker:', error);
    throw error;
  }
}

export async function deleteTrackerAPI(idToken, trackerId,) {
  try {
      
    const response = await axios.delete(`https://beestock-yoda-dev.haslle.com/trackers/${trackerId}`,
    {
      headers: {
        Authorization: idToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data:', error);
    throw error;
  }
}


export async function saveNewTransactionAPI(idToken, ticker, data) {
  const invested = data.purchase_price * data.quantity
  try {
    const requestBody = {
      ticker: ticker,
      // ticker: "NVDA",
      status: "OPEN",
      purchase_price: data.purchase_price,
      purchased_at: data.purchased_at,
      // purchased_at: "10/20/2023",
      invested_amount: invested,
      quantity: data.quantity,
      note: data.note,
    };


    const response = await axios.post('https://beestock-yoda-dev.haslle.com/transactions/', requestBody, {
      headers: {
        Authorization: idToken,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error submitting data to add a new Transaction:', error);
    throw error;
  }
}

export async function deleteTransactionAPI(idToken, transactionId,) {
  try {
      
    const response = await axios.delete(`https://beestock-yoda-dev.haslle.com/transactions/${transactionId}`,
    {
      headers: {
        Authorization: idToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting data:', error);
    throw error;
  }
}

export async function patchTransactionAPI(idToken, transactionId, data) {
  const invested = data.purchase_price * data.quantity
  try {
    console.log('PATCH: ', data)
    const requestBody = {
      status: "OPEN",
      purchase_price: data.purchase_price,
      purchased_at: data.purchased_at,
      // purchased_at: "10/20/2023",
      invested_amount: invested,
      quantity: data.quantity,
      note: data.note,
    };

    const response = await axios.patch(`https://beestock-yoda-dev.haslle.com/transactions/${transactionId}`, requestBody, {
      headers: {
        Authorization: idToken,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error submitting data to add a new Transaction:', error);
    throw error;
  }
}