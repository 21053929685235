<template>
  <v-card lass="d-flex flex-column fill-height" height="650" width="500">
    <v-sheet class="mt-5 ml-10" height="60">
      <v-card-text> SETTINGS </v-card-text>
    </v-sheet>

    <v-sheet width="400" class="mx-auto flex-grow-1">
      <v-form @submit.prevent>
        <v-text-field
          v-model="userInfo.email"
          label="email"
          variant="outlined"
          disabled
        ></v-text-field>
        <v-text-field
          v-model="userInfo.telegram_username"
          label="telegram user name"
          variant="outlined"
          disabled
        ></v-text-field>

        <v-text-field
          v-model="userInfo.telegram_chat_id"
          disabled
          label="telegram id"
          variant="outlined"
        ></v-text-field>
        <v-switch
          v-model="model"
          :label="`telegram alerts: ${model.toString()}`"
          color="secondary"
          inset
        ></v-switch>
        <!-- <v-text-field
          v-model="userInfo.alert_schedule.hour"
          :rules="timeRules"
          type="number"
          label="alert time"
          variant="outlined"
        ></v-text-field> -->
        <v-text-field
          v-model="userInfo.subscription_plan"
          disabled
          label="subsctiption plan"
        ></v-text-field>
        <v-sheet class="align-end">
          <v-row>
            <v-col cols="6">
              <v-btn type="submit" block class="mt-2" disabled>Submit</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn type="close" block class="mt-2">Close</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form>
    </v-sheet>
  </v-card>
</template>

<script>
import store from "@/store";

export default {
  name: "SettingsContent",
  data() {
    return {
      userData: null,
      model: true,
    };
  },

  computed: {
    userInfo() {
      const userData = store.getUserData();
      // Add your desired text to the userData object or its properties here
      // For example, to add a prefix to the user's name:
      console.log(userData);
      return userData.user[0];
    },
  },
};
</script>
