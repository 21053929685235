import { defineStore } from 'pinia';
import { getPortfolio } from "@/api/yoda_api";
import { getTrackers } from "@/api/yoda_api";
import { getSharesListSearch} from "@/api/yoda_api";
import { getPortfolioTransactions } from "@/api/yoda_api";
import { getShareFinanceData } from "@/api/yoda_api";
import { getShareInfo } from "@/api/yoda_api";
import { useUserDataStore } from "@/store/userDataStore.js";


export const usePortfolioDataStore = defineStore({
  id: 'portfolioData',
  state: () => ({
    portfolioData: {},
    selectedPortfolioShare: {},
    selectedShareTransactions: {},
    trackersData: {},
    sharesListSearch: {},
    foundNewTicker:{},
    shareFinanceData: {},
    shareInfo: {},
  }),
  actions: {
    
    async fetchPortfolioData() {
      // const userDataStore = useUserDataStore(); 
      const idToken = localStorage.getItem('idToken');
      console.log ("token to be used in fetching transactions: ", idToken)
      // const idToken = userDataStore.idToken; 
      try {
        
        const portfolioResponse = await getPortfolio( idToken);
        this.portfolioData = portfolioResponse;
      
        return portfolioResponse;
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    },

    async fetchPortfolioTransactions( ticker) {
      const userDataStore = useUserDataStore(); 
      const idToken = userDataStore.idToken; 

      try {
        const portfolioTransactionsResponse = await getPortfolioTransactions(idToken, ticker);
        this.selectedShareTransactions = portfolioTransactionsResponse;       
        return portfolioTransactionsResponse;
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    },

    setSelectedPortfolioShare(data){
      this.selectedPortfolioShare = data
    },

    async saveNewTransactions(ticker) {
      try {
        const portfolioTransactionsResponse = await getPortfolioTransactions(useUserDataStore.idToken, ticker);
        this.selectedShareTransactions = portfolioTransactionsResponse;       
        return portfolioTransactionsResponse;
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    },

    async fetchTrackersData() {
      const userDataStore = useUserDataStore(); 
      const idToken = userDataStore.idToken;
      console.log("Start API fetch tracking", idToken)
      try {
        const trackersResponse = await getTrackers(idToken);
        this.trackersData = trackersResponse;

        return trackersResponse;
      } catch (error) {
        console.error(`Error fetching trackers`, error);
        return null;
      }
    },

    async fetchShareFinanceData(ticker) {
      const userDataStore = useUserDataStore(); 
      const idToken = userDataStore.idToken;
      
      try {
        const ShareFinanceDataResponse = await getShareFinanceData(idToken, ticker);
        this.shareFinanceData = ShareFinanceDataResponse;
  
        return ShareFinanceDataResponse;
      } catch (error) {
        console.error(`Error fetching trackers`, error);
        return null;
      }
    },

    async fetchShareInfo(ticker) {
      const userDataStore = useUserDataStore(); 
      const idToken = userDataStore.idToken;
   
      try {
        const shareInfoResponse = await getShareInfo(idToken, ticker);
        this.shareInfo = shareInfoResponse;
     
        return shareInfoResponse;
      } catch (error) {
        console.error(`Error fetching share info`, error);
        return null;
      }
    },


    async fetchSharesListSearch(ticker) {
      const userDataStore = useUserDataStore(); 
      const idToken = userDataStore.idToken;
     
      try {
        const sharesSearchResponse = await getSharesListSearch(idToken, ticker);
        this.sharesListSearch = sharesSearchResponse;
      
        return sharesSearchResponse;
      } catch (error) {
        console.error(`Error fetching shares search`, error);
        return null;
      }
    },

    setFoundNewTicker(data){
      this.foundNewTicker = data
    },

    logout() {
      this.portfolioData = {};
      this.selectedPortfolioShare = {};
      this.selectedShareTransactions = {};
    },
  },
});