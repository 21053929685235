<template>
  <div class="portfolio-view">
    <CardDashboard />
    <MainRoundedForm>
      <!-- <template #title>
        <PortfolioTitle class="title-margin" />
      </template> -->

      <template #content>
        <PortfolioContent class="content-margin" />
      </template>
    </MainRoundedForm>
  </div>
</template>

<script>
import MainRoundedForm from "@/components/reusable/MainRoundedForm.vue";
import PortfolioContent from "@/components/portfolio/PortfolioContent.vue";
import CardDashboard from "@/components/portfolio/Cards/CardDashboard.vue";
export default {
  components: {
    MainRoundedForm,
    PortfolioContent,
    CardDashboard,
  },
};
</script>

<style></style>
