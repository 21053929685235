// src/store.js
class Store {
  constructor() {
    this.state = {
      userData: this.getFromLocalStorage("userData") || null,
    };
  }

  logState() {
    console.log(this.state);
  }

  setUserData(data) {
    this.state.userData = data;
    this.saveToLocalStorage("userData", data);
  }

  getUserData() {
    return this.state.userData;
  }

  saveToLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getFromLocalStorage(key) {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  }
  logout() {
    this.state.userData = null;
    this.removeFromLocalStorage("userData");
  }
  removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }  
}

export default new Store();
