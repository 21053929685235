<template>
  <DataTable
    resizableColumns
    ref="dataTable"
    sortField="name"
    :sortOrder="1"
    columnResizeMode="fit"
    class="custom-datatable p-datatable-sm"
    dataKey="id"
    tableClass="editable-cells-table"
    :value="portfolioDataStore.portfolioData.portfolio"
    @row-click="toggleHoldingDialog($event)"
    tableStyle="min-width: calc(50rem - 5px * 5);"
  >
    <template #header> </template>

    <Column
      v-for="col of columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      :sortable="col.sortable ? true : false"
      :style="`width: ${col.width}; max-width: ${col.width};`"
    >
      <template v-if="col.field === 'logo'" #body="{ data }">
        <v-avatar
          color="darkbackround"
          class="pa-2 ma-0"
          rounded="1"
          :image="data.logo"
          size="35"
        ></v-avatar>
      </template>
      <template v-else #body="{ data }">
        {{ col.prefix ? col.prefix : "" }} {{ data[col.field] }}
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";

export default {
  props: {
    columns: Array,
  },
  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();
    return {
      userDataStore,
      portfolioDataStore,
    };
  },
  data() {
    return {
      showDetails: false,
      selectedPortfolioShare: {},
      isNewShareClicked: false,
      total_result: "",
      total_invested: "",
      captionHeaderStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "500",
        "line-height": "1.25",
        "letter-spacing": "0.03333em",
        "text-transform": "uppercase",
        "padding-top": "15px",
        "padding-bottom": "15px",
        background: "#f7f7f7",
      },
      captionBodyStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "500",
        "line-height": "1.25",
        "letter-spacing": "0.03333em",
        cursor: "pointer",
      },
    };
  },
  async created() {
    console.log("Portfolio data is being refreshed at creation");
    this.refreshPortfolioData();
  },
  methods: {
    transacionsAddedForNewShare() {
      this.refreshPortfolioData();
      this.showDetails = false;
    },
    async refreshPortfolioData() {
      try {
        await this.portfolioDataStore.fetchPortfolioData(
          this.userDataStore.idToken
        );
        this.total_invested =
          this.portfolioDataStore.portfolioData.total_invested;
        this.total_result = this.portfolioDataStore.portfolioData.total_result;
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    },

    toggleHoldingDialog(event) {
      this.selectedPortfolioShare = event.data;
      console.log("Row click!", this.selectedPortfolioShare);
      this.portfolioDataStore.setSelectedPortfolioShare(event.data);
      this.showDetails = !this.showDetails;
      this.$emit("holding-row-clicked", event.data);
      this.fetchPortfolioTransactions();
    },
    addNewShareClick() {
      this.showDetails = !this.showDetails;
      this.isNewShareClicked = true;
    },
    async fetchPortfolioTransactions() {
      console.log("fetchPortfolioTransactions");
      try {
        this.portfolioDataStore.fetchPortfolioTransactions(
          this.portfolioDataStore.selectedPortfolioShare.ticker
        );
      } catch (error) {
        console.error("Error fetching share transactions:", error);
      }
    },
  },
};
</script>
