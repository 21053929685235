<template>
  <div class="search-container">
    <v-autocomplete
      v-model:search="search"
      :loading="loading"
      :items="items"
      class="mx-4"
      hide-no-data
      hide-details
      item-title="name"
      item-value="ticker"
      clearable
      label="Search for your share"
      variant="underlined"
      @update:modelValue="onItemSelected"
    >
      <template v-slot:item="{ props, items }">
        <v-list-item v-bind="props" :title="items?.name"
          >{{ props.value }} - {{ props.title }}
        </v-list-item>
      </template></v-autocomplete
    >
  </div>
</template>

<script>
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { ref } from "vue";

export default {
  props: {
    shareSelectedInView: Object,
  },

  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();
    const items = ref([]);
    const search = ref(""); // Create a reactive search property

    return {
      userDataStore,
      portfolioDataStore,
      items,
      search, // Expose the search property to the template
    };
  },

  data() {
    return {
      searchQuery: "",
      searchResults: [],
      selectedIndex: -1,
      mouseOverIndex: -1,
      exchange: "",
      loading: false,
      value: "",
      select: null,
      selectedShare: "",
    };
  },

  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    shareSelectedInView: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.onItemSelected(newVal);
        }
      },
    },
  },

  mounted() {
    this.fetchPortfolioTransactions();
  },

  computed: {
    shareCompleteTrackersData() {
      if (
        this.portfolioDataStore.foundNewTicker != null &&
        this.portfolioDataStore.shareFinanceData != null &&
        Object.keys(this.portfolioDataStore.foundNewTicker).length > 0 &&
        Object.keys(this.portfolioDataStore.shareFinanceData).length > 0
      ) {
        let finalObject = {
          ticker: this.portfolioDataStore.shareInfo.ticker,
          name: this.portfolioDataStore.shareInfo.name,
          logo: this.portfolioDataStore.shareInfo.logo,
          current_price: this.portfolioDataStore.shareFinanceData.current_price,
          trackers: [],
        };

        const kpiIds = [1, 2, 3, 4];

        // Iterate through the desired KPI IDs
        kpiIds.forEach((kpiId) => {
          // Find an existing alert_config with the current kpiId
          let existingAlertConfig = this.portfolioDataStore.trackersData
            .filter(
              (tracker) =>
                tracker.ticker === this.portfolioDataStore.shareInfo.ticker
            )
            .flatMap((tracker) => tracker.alert_configs)
            .find((alertConfig) => alertConfig.kpi_id === kpiId);

          // Initialize an empty tracker object
          let tracker = {
            kpi_id: kpiId,
          };

          if (existingAlertConfig?.id) {
            tracker = {
              ...tracker,
              config_id: existingAlertConfig.id,
            };
          }

          // If an existing alert_config is found, add the existing data and set the existing flag to true
          if (existingAlertConfig) {
            tracker = {
              ...tracker,
              ...existingAlertConfig,
              existing: true,
            };
          } else {
            tracker = {
              existing: false,
            };
          }

          switch (kpiId) {
            case 1:
              tracker.financial_value =
                this.portfolioDataStore.shareFinanceData.dfc;
              tracker.id = "1";
              break;
            case 2:
              tracker.financial_value =
                this.portfolioDataStore.shareFinanceData.year_high;
              tracker.id = "2";
              break;
            case 3:
              tracker.financial_value =
                this.portfolioDataStore.shareFinanceData.target_consensus;
              tracker.id = "3";
              break;
            case 4:
              tracker.financial_value =
                this.portfolioDataStore.shareFinanceData.current_price;
              tracker.id = "4";
              break;
            default:
              break;
          }

          // Push the tracker object to the final object's trackers array
          finalObject.trackers.push(tracker);
        });

        return finalObject;
      } else {
        return "";
      }
    },
  },

  methods: {
    async querySelections(v) {
      this.loading = true;

      try {
        await this.portfolioDataStore.fetchSharesListSearch(v);
        const sharesListSearch = this.portfolioDataStore.sharesListSearch;

        this.items = sharesListSearch;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching SharesSearch data:", error);
        this.loading = false;
      }
    },

    async fetchPortfolioTransactions() {
      try {
        this.portfolioDataStore.fetchPortfolioTransactions(
          this.portfolioDataStore.foundNewTicker
        );
      } catch (error) {
        console.error("Error fetching share transactions:", error);
      }
    },

    async onItemSelected(item) {
      this.selectedShare = item;
      console.log("see an item: ", item);

      this.portfolioDataStore.setFoundNewTicker(item);

      if (this.portfolioDataStore.foundNewTicker) {
        try {
          await this.portfolioDataStore.fetchShareFinanceData(
            this.portfolioDataStore.foundNewTicker
          );
        } catch (error) {
          console.error("Error fetching SharesSearch data:", error);
        }
        try {
          await this.portfolioDataStore.fetchShareInfo(
            this.portfolioDataStore.foundNewTicker
          );
          this.portfolioDataStore.setSelectedPortfolioShare(
            this.portfolioDataStore.shareInfo
          );
        } catch (error) {
          console.error("Error fetching SharesSearch data:", error);
        }
      }
    },
  },
};
</script>
