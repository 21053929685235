<template>
  <v-sheet class="bg-background">
    <v-row class="mb-10">
      <TradingViewLine />
    </v-row>
    <v-row>
      <v-col cols="8">
        <MainRoundedForm title="History of your portfolio">
          <!-- <template #title>
          <PortfolioTitle class="title-margin" />
        </template> -->
          <template #content>
            <BalanceChart />
          </template>
        </MainRoundedForm>
      </v-col>
      <v-col cols="4  ">
        <MainRoundedForm title="Portfolio sectors distribution">
          <!-- <template #title>
          <PortfolioTitle class="title-margin" />
        </template> -->
          <template #content>
            <PortfolioChart />
          </template>
        </MainRoundedForm>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import MainRoundedForm from "@/components/reusable/MainRoundedForm.vue";
import PortfolioChart from "@/components/dashboard/charts/DashboardPortfolioChart.vue";
import TradingViewLine from "@/components/dashboard/charts/DashbordTradingViewLine.vue";
import BalanceChart from "@/components/dashboard/charts/DashboardBalanceChart.vue";

export default {
  components: {
    MainRoundedForm,
    BalanceChart,
    TradingViewLine,
    PortfolioChart,
  },
};
</script>

<style></style>
