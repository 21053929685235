<template>
  <v-navigation-drawer v-model="drawer" :rail="rail" permanent>
    <!-- @click="rail = false" -->
    <v-sheet class="d-flex flex-column fill-height">
      <v-sheet
        height="120"
        class="d-flex justify-center align-center bg-surface-variant pb-3"
      >
        <div class="logo">
          <img src="@/assets/logo.png" alt="Logo" />
          <!-- <h2 class="app-title">Application Name</h2> -->
        </div>
      </v-sheet>
      <v-divider class="border-opacity-100" color="grey"></v-divider>
      <v-sheet class="flex-grow-1 bg-surface-variant my">
        <v-list density="default" class="mt-7">
          <v-list-item
            prepend-icon="mdi-view-dashboard"
            active-color="secondary"
            variant="plain"
            title="Dashboard"
            value="dashboard"
            @click="$router.push('/user_home/dashboard')"
            :class="{ active: $route.path === '/user_home/dashboard' }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-finance"
            active-color="secondary"
            variant="plain"
            title="Trackers"
            value="trackers"
            @click="$router.push('/user_home/trackers')"
            :class="{ active: $route.path === '/user_home/trackers' }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-shopping-outline"
            active-color="secondary"
            variant="plain"
            title="Portfolio"
            value="portfolio"
            @click="$router.push('/user_home/portfolio')"
            :class="{ active: $route.path === '/user_home/portfolio' }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-shopping-outline"
            active-color="secondary"
            variant="plain"
            title="Old Portfolio"
            value="old_portfolio"
            @click="$router.push('/user_home/old_portfolio')"
            :class="{ active: $route.path === '/user_home/old_portfolio' }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-chart-arc"
            active-color="secondary"
            variant="plain"
            title="Analytics!"
            value="analytics"
            @click="$router.push('/user_home/analytics')"
            :class="{ active: $route.path === '/user_home/analytics' }"
          ></v-list-item>
        </v-list>
      </v-sheet>
      <v-divider class="border-opacity-100" color="grey"></v-divider>
      <v-sheet class="align-end bg-surface-variant">
        <v-list>
          <div style="flex-grow: 2"></div>
          <v-list-item
            prepend-icon="mdi-cog"
            variant="plain"
            title="Settings"
            value="settings"
            @click="dialog = true"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-exit-to-app"
            variant="plain"
            title="Logout"
            value="logout"
            @click="logout"
          ></v-list-item>

          <v-list-item nav>
            <template v-slot:append>
              <v-btn
                variant="text"
                icon="mdi-chevron-left"
                @click.stop="rail = !rail"
              ></v-btn>
            </template>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-sheet>
  </v-navigation-drawer>

  <v-dialog v-model="dialog" width="auto">
    <SettingsContent />
  </v-dialog>
</template>

<script>
import { ref } from "vue";
import "@/styles.css";
import firebase from "@/firebase";
import SettingsContent from "@/components/settings/SettingsContent.vue";
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { useRouter } from "vue-router";

export default {
  name: "NavigationPanel",

  components: {
    SettingsContent,
  },

  props: {
    currentTab: {
      type: Number,
    },
  },

  setup() {
    const router = useRouter();
    const userDataStore = useUserDataStore();

    const showSettingsPopup = ref(false);
    const dialog = ref(false);
    const drawer = ref(true);
    const rail = ref(false);
    const userData = ref(null);
    const portfolioDataStore = usePortfolioDataStore();

    function selectTab(tab) {
      this.$emit("update-tab", tab);
    }

    async function logout() {
      try {
        await firebase.auth().signOut();
        userDataStore.logout();
        portfolioDataStore.logout();
        router.push("/");
      } catch (error) {
        console.error("Logout error:", error);
      }
    }

    function openSettingsPopup() {
      showSettingsPopup.value = true;
    }

    return {
      showSettingsPopup,
      dialog,
      drawer,
      rail,
      userData,
      userDataStore,
      selectTab,
      logout,
      openSettingsPopup,
      portfolioDataStore,
    };
  },
};
</script>

<style scoped>
.sidebar {
  /* padding: 1rem; */
  background-color: #fff;
}

.nav-item:hover {
  color: black;
}

.nav-item.active {
  background-color: #fcb43a;
  color: #fff;
}

.settings-btn:hover,
.logout-btn:hover {
  border: 1px solid var(--light-text-color);
}

.logo-transition {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.logo img {
  height: auto; /* Maintain the aspect ratio */
  padding-top: 15px; /* Add top padding to the logo image */
}
</style>
