import { createApp } from "vue";
import App from "./App.vue";
import "./styles.css";

import router from "./router";
import "./styles.css";
import '@mdi/font/css/materialdesignicons.min.css';

import { auth } from "./firebase";

import VueApexCharts from 'vue3-apexcharts' 

import vuetify from './plugins/vuetify'
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '/src/custom-theme-prime.css';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Paginator from 'primevue/paginator';
import Row from 'primevue/row';
import Toast from 'primevue/toast';
import PrimeVue from 'primevue/config';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import ToastService from 'primevue/toastservice'


import { createPinia } from "pinia";
import { useUserDataStore } from "@/store/userDataStore";


async function handleUserRedirect(user) {
  const userDataStore = useUserDataStore();

  if (user) {
    try {
      const token = await user.getIdToken(); // Get the user's token
      userDataStore.setIdToken(token); // Update the Pinia store with the token

      // Access the token from the store
      const storedToken = userDataStore.idToken;
      console.log("Stored token:", storedToken);
      if (this.userDataStore.userData.status === "active") {
        router.push("/user_dashboard/dashboard");
      }
    } catch (error) {
      console.log("Error in token processing", error)
    }
  } else {
    userDataStore.setIdToken(""); 
  }
}

const app = createApp(App);

app.use(router);
app.use(vuetify);
app.use(PrimeVue); 
app.use(VueApexCharts);
app.use(createPinia());
app.use(ToastService)
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Row', Row);
// eslint-disable-next-line
app.component('Toast', Toast);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('ColumnGroup', ColumnGroup);
app.component('Paginator', Paginator);
app.component('ApexChart', VueApexCharts);
app.mount("#app");

auth.onAuthStateChanged(handleUserRedirect);

