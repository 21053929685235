import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "@/views/LandingPage.vue";
import UserHome from "@/views/UserHome/UserHome.vue";
import DashboardView from "@/views/UserHome/dashboard/DashboardView.vue";
import TrackersView from "@/views/UserHome/trackers/TrackersView.vue";
import OldPortfolioView from "@/views/UserHome/oldPortfolio/OldPortfolioView.vue";
import PortfolioView from "@/views/UserHome/portfolio/PortfolioView.vue";
import AnalyticsView from "@/views/UserHome/analytics/AnalyticsView.vue";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/user_home",
    name: "Home",
    component: UserHome,
    children: [
      {
        path: "dashboard", 
        name: "Dashboard",
        component: DashboardView,
      },
      {
        path: "trackers", 
        name: "Trackers",
        component: TrackersView,
      },
      {
        path: "portfolio", 
        name: "Portfolio",
        component: PortfolioView,
      },
      {
        path: "old_portfolio", 
        name: "oldPortfolio",
        component: OldPortfolioView,
      },
      {
        path: "analytics", 
        name: "Analytics",
        component: AnalyticsView,
      },
      {
        path: "", // Empty path for default child route
        redirect: "dashboard",
      },
    ],
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
