<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="formattedDate"
            label="Date"
            required
            variant="underlined"
            density="compact"
            color="secondary"
            type="date"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            prefix="$"
            v-model.number="newPrice"
            label="Price"
            required
            variant="underlined"
            density="compact"
            color="secondary"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            v-model.number="newQuantity"
            label="Quantity"
            color="secondary"
            required
            variant="underlined"
            density="compact"
          ></v-text-field>
        </v-col>
        <v-col cols="14" md="2">
          <v-text-field
            v-model="calculateInvestedAmount"
            required
            variant="underlined"
            density="compact"
          ></v-text-field>
        </v-col>

        <v-col cols="14" md="1">
          <v-btn
            size="default"
            color="secondary"
            icon="mdi-check"
            density="comfortable"
            rounded="lg"
            @click="addPortfolioTransaction"
            style="margin-left: 10px"
          ></v-btn>
        </v-col>

        <v-col cols="14" md="1">
          <v-btn
            size="default"
            color="secondary"
            icon="mdi-close"
            density="comfortable"
            rounded="lg"
            style="margin-left: 10px"
          >
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import store from "@/store";
// import { addPortfolioTransaction } from "@/api/yoda_api.js";

export default {
  props: {
    userId: Number,
    selectedShare: Object,
    isNewShareSelected: Boolean,
  },
  data() {
    return {
      newQuantity: null, // or provide an initial value
      currentDate: new Date(),
      newInvestedAmount: null,
      newPrice: null,
      formattedDate: "",
    };
  },
  methods: {
    async saveNewTransaction() {
      // Collect your form data

      const data = {
        date: this.formattedDate,
        purchase_price: this.newPrice,
        // quantity: this.newQuantity,
        status: "OPEN",
        // user_id: this.userData.user[0].id,
        share_id: this.selectedShare.id,
        // invested_amount: this.calculateInvestedAmount,
      };
      console.log("start  -  ", data.share_id);
      try {
        // await saveNewTransactionAPI(
        //   userDataStore.idToken,
        //   portfolioDataStore.selectedPortfolioShare.ticker,
        //   transactionToSave
        // );

        // portfolioDataStore.fetchPortfolioTransactions(
        //   portfolioDataStore.selectedPortfolioShare.ticker
        // );
        // showNewTransaction.value = false;
        // editingRows.value = null;
        // refreshPortfolioData();
      } catch (error) {
        console.error("Error adding tracker:", error);
      }
    },
  },

  computed: {
    userData() {
      return store.getUserData(); // Access the user data from the store
    },
    calculateInvestedAmount() {
      return this.newPrice && this.newQuantity
        ? (this.newPrice * this.newQuantity).toFixed(2)
        : null;
    },
  },

  beforeMount() {
    const year = this.currentDate.getFullYear();
    const month = String(this.currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(this.currentDate.getDate()).padStart(2, "0");

    this.formattedDate = `${year}-${month}-${day}`;
    console.log(this.selectedShare);
  },
};
</script>

<style>
.v-col {
  margin-right: "120px";
}
</style>
