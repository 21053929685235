<template>
  <v-sheet class="pt-0 pb-5 pl-2">
    <v-row class="d-flex align-center justify-space-between mt-1">
      <v-col cols="6">
        <div style="font-size: 17px; color: var(--dark-text-color)">
          Portfolio
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn @click="addNewShareClick"> Add new share </v-btn>
      </v-col>
    </v-row>
  </v-sheet>

  <DataTable
    resizableColumns
    ref="dataTable"
    sortField="name"
    :sortOrder="1"
    columnResizeMode="fit"
    class="custom-datatable p-datatable-sm"
    dataKey="id"
    tableClass="editable-cells-table"
    :value="portfolioDataStore.portfolioData.portfolio"
    @row-click="toggleDetails($event)"
    tableStyle="min-width: calc(50rem - 5px * 5);"
  >
    <template #header> </template>
    <Column
      v-for="col of columnsLogo"
      :key="col.field"
      :field="portfolioDataStore.portfolioData.portfolio"
      :header="col.header"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      :style="`width: ${col.width}; max-width: ${col.width};`"
    >
      <template #body="{ data }">
        <v-avatar
          color="darkbackround"
          class="pa-2 ma-0"
          rounded="1"
          :image="data.logo"
          cosize="35"
        ></v-avatar>
      </template>
    </Column>

    <Column
      v-for="col of columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      :sortable="col.sortable"
      :style="`width: ${col.width}; max-width: ${col.width};`"
    >
      <template #body="{ data }">
        {{ col.prefix }} {{ data[col.field] }}
      </template>
    </Column>
    <Column
      v-for="col of columnsNumbers"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      :sortable="col.sortable"
      :style="`width: ${col.width}; max-width: ${col.width};`"
    >
      <template #body="{ data }">
        {{ col.prefix }} {{ data[col.field].toFixed(2) }}
      </template>
    </Column>
    <Column
      key="current_result"
      field="current_result"
      header="Current result "
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      sortable
      style="width: 10%"
    >
      <template #body="{ data }">
        <span
          :class="{
            'error-color': data.current_result < 0,
            'positive-color': data.current_result > 0,
          }"
          >{{ data.current_result }}</span
        >
      </template>
    </Column>
    <ColumnGroup type="footer">
      <Row>
        <Column footer="" :colspan="5" footerStyle="text-align:right" />
        <Column :footer="total_invested" />
        <Column :footer="total_result" />
      </Row>
    </ColumnGroup>
  </DataTable>

  <div class="overlay" v-if="showDetails" @click="toggleDetails"></div>
  <OldPortfolioDetails
    v-if="showDetails"
    :selectedPortfolioShare="selectedPortfolioShare"
    :isNewShareClicked="isNewShareClicked"
    @close-details="toggleDetails"
    @transactions-updated="refreshPortfolioData"
    @transactions-updated-for-new-share="transacionsAddedForNewShare"
  />
</template>

<script>
import OldPortfolioDetails from "./OldPortfolioDetails.vue";
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";

export default {
  data() {
    return {
      columns: [
        {
          header: "Ticker",
          field: "ticker",
          sortable: "sortable",
          width: "5%",
        },
        {
          header: "Name",
          field: "name",
          sortable: "sortable",
          width: "15%",
        },
      ],
      columnsNumbers: [
        { header: "Price", field: "current_price", width: "10%", prefix: "$" },
        { header: "Position", field: "total_position", width: "10%" },
        {
          header: "Invested",
          field: "total_cost",
          sortable: "sortable",
          width: "10%",
          prefix: "$",
        },
      ],
      columnsAction: [
        { header: "", field: "edit", editor: false },
        { header: "", field: "delete", editor: "false" },
      ],
      columnsLogo: [{ header: "", field: "logo", editor: false, width: "5%" }],

      showDetails: false,
      selectedPortfolioShare: {},

      isNewShareClicked: false,
      total_result: "",
      total_invested: "",
      captionHeaderStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "500",
        "line-height": "1.25",
        "letter-spacing": "0.03333em",
        "text-transform": "uppercase",
        "padding-top": "15px",
        "padding-bottom": "15px",
        background: "#f7f7f7",
      },
      captionBodyStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "500",
        "line-height": "1.25",
        "letter-spacing": "0.03333em",
        cursor: "pointer",

        // "text-transform": "uppercase",
      },
    };
  },
  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();
    return {
      userDataStore,
      portfolioDataStore,
    };
  },

  components: {
    OldPortfolioDetails, // Register the child component
    // RatingCircle,
  },
  async created() {
    console.log("Portfolio data is being refreshed at creation");
    this.refreshPortfolioData();
  },

  methods: {
    transacionsAddedForNewShare() {
      this.refreshPortfolioData();
      this.showDetails = false;
    },

    async refreshPortfolioData() {
      try {
        await this.portfolioDataStore.fetchPortfolioData(
          this.userDataStore.idToken
        );
        this.total_invested =
          this.portfolioDataStore.portfolioData.total_invested;
        this.total_result = this.portfolioDataStore.portfolioData.total_result;
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    },

    toggleDetails(event) {
      this.isNewShareClicked = false;
      this.selectedPortfolioShare = event.data;
      console.log("Row click!", this.selectedPortfolioShare);
      this.portfolioDataStore.setSelectedPortfolioShare(event.data);
      this.showDetails = !this.showDetails;
    },

    addNewShareClick() {
      this.showDetails = !this.showDetails;
      this.isNewShareClicked = true;
    },
  },
};
</script>

<style>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 50;
}
.blurred {
  filter: blur(4px);
}
.header {
  display: flex; /* Added */
  justify-content: space-between; /* Added */
  align-items: center; /* Added */
}
.add-tracker-btn {
  background-color: var(--light-text-color);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1 rem;
  padding: 15px 20px;
  margin-right: 40px;
  margin-top: 20px;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out;
}

.p-datatable .p-datatable-header {
  display: none;
  padding: 0 0;
}

.error-color {
  color: #e74c3c;
}

.positive-color {
  color: green;
}
</style>
