<template>
  <router-view v-if="renderView"></router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      renderView: true,
    };
  },
  mounted() {
    this.$router.afterEach(() => {
      this.renderView = false;
      this.$nextTick(() => {
        this.renderView = true;
      });
    });
  },
};
</script>
