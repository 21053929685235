<template>
  <v-card
    class="px-6 pb-6 pt-3"
    rounded="lg"
    max-width="100%"
    width="100%"
    style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.05)"
  >
    <!-- <v-card-title>{{ title }}</v-card-title> -->
    <slot name="content" />
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      title2: "123",
    };
  },

  props: {
    title: String,
  },
};
</script>

<style scoped>
::slotted([slot="content"]) {
  margin: 25px; /* Adjust the margin value to your desired amount */
}
</style>
