<template>
  <div>
    <div>Analytics should be here :)</div>
    <v-card class="py-10">
      <v-btn id="run-api-btn" @click="runAllApis">Run 3 API</v-btn>
    </v-card>
    <div v-if="apiResults.length > 0">
      <h3>API Call Results:</h3>
      <ul>
        <li v-for="(result, index) in apiResults" :key="index">
          API {{ index + 1 }}: {{ result.duration.toFixed(0) }} ms
        </li>
      </ul>
      <p>Total time: {{ totalTime.toFixed(0) }} ms</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      apiResults: [],
      totalTime: 0,
    };
  },

  methods: {
    async runAllApis() {
      this.apiResults = [];

      const apiCalls = [this.callApi1, this.callApi2, this.callApi3];

      const startTotal = performance.now();
      const promises = apiCalls.map((callApi) => {
        const start = performance.now();
        return callApi()
          .then(() => {
            const end = performance.now();
            this.apiResults.push({ duration: end - start });
          })
          .catch((error) => console.error("Error running API:", error));
      });

      await Promise.all(promises);
      const endTotal = performance.now();
      this.totalTime = endTotal - startTotal;
    },

    async callApi1() {
      // Replace this with your API 1 call logic
      try {
        const response1 = await axios.get(
          `https://financialmodelingprep.com/api/v4/price-target-consensus?symbol=NVDA&apikey=834c4050038d3b29049a7ad2c20d41d8`
        );
        console.log("API 1", response1);
      } catch (error) {
        console.error("Error fetching API 1 data:", error);
      }
    },

    async callApi2() {
      // Replace this with your API 2 call logic
      try {
        const response2 = await axios.get(
          `https://financialmodelingprep.com/api/v3/company/discounted-cash-flow/NVDA?apikey=834c4050038d3b29049a7ad2c20d41d8`
        );
        console.log("API 2", response2);
      } catch (error) {
        console.error("Error fetching API 2 data:", error);
      }
    },

    async callApi3() {
      // Replace this with your API 3 call logic
      try {
        const response3 = await axios.get(
          `https://financialmodelingprep.com/api/v3/company/profile/NVDA?apikey=834c4050038d3b29049a7ad2c20d41d8`
        );
        console.log("API 3", response3);
      } catch (error) {
        console.error("Error fetching API 3 data:", error);
      }
    },
  },
};
</script>

<style scoped></style>
