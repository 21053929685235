<!-- TrackerCircle.vue -->
<template>
  <div class="tracker-container">
    <div class="tracker-circle" :style="circleStyle">
      <span class="mdi" :class="kpiIcon"></span>
      <div class="tracker-value">{{ finalValue }}</div>
    </div>
  </div>
</template>

<script>
import { kpiProperties } from "@/constants/kpiProperties";

export default {
  props: {
    kpi_id: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
    },
    color: {
      type: String,
    },
    source: {
      type: String,
    },
  },
  computed: {
    circleStyle() {
      return {
        backgroundColor: this.getCustomColor(this.color),
      };
    },
    kpiIcon() {
      const kpi = kpiProperties[this.kpi_id];
      return kpi ? kpi.tracker_icon : "";
    },
    finalValue() {
      return this.value > 0 ? this.value + "%" : this.value + "%";
    },
  },
  methods: {
    getCustomColor(ratingColor) {
      const colorMap = {
        green: "#88E3AE",
        yellow: "#FEDE00",
        red: "#D6D4D2",
      };
      return colorMap[ratingColor] || ratingColor;
    },
  },
};
</script>

<style scoped>
.tracker-container {
  display: inline-block;
  margin-right: 8px;
}
.tracker-circle {
  width: 41px;
  height: 41px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
  position: relative;
  /* border: 1px solid #424242 ; */
}
.tracker-value {
  position: absolute;
  bottom: 1px;
  font-size: 10px;
  font-weight: bold;
}
</style>
