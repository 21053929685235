<template>
  <div class="rating-circle" :style="circleStyle">
    <span class="mdi" :class="iconClass"></span>
  </div>
</template>

<script>
export default {
  props: {
    ratingColor: {
      type: String,
    },
    colors: {
      type: Object,
      default: () => ({
        green: "green",
        yellow: "yellow",
        red: "red",
      }),
    },
  },
  computed: {
    circleStyle() {
      return {
        backgroundColor: this.colors[this.ratingColor],
      };
    },
    iconClass() {
      switch (this.ratingColor) {
        case "green":
          return "mdi-check-all";
        case "yellow":
          return "mdi-shield-alert-outline";
        case "red":
          return "mdi-close";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.rating-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.mdi {
  font-size: 25px;
  color: var(--dark-text-color);
}
</style>
