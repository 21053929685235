<template>
  <div v-if="isVisible" class="popup-overlay" @click="$emit('close')">
    <div class="popup" @click.stop>
      <AddKpiContent
        :kpiToAdd="kpiToAdd"
        @animation-finished="handleAnimationFinished"
        @tracker-added="$emit('tracker-added')"
        @kpi-updated="$emit('kpi-updated', $event)"
      />
      <button class="popup-close" @click="$emit('close')">x</button>
    </div>
  </div>
</template>

<script>
import AddKpiContent from "./AddKpiContent.vue";

export default {
  name: "KpiPopup",
  components: {
    AddKpiContent,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    kpiToAdd: Object,
  },

  data() {
    return {
      currentShareName: null,
      currentTrackerName: null,
      currentUserId: null,
      currentShareTicker: null,
      currentKpiId: null,
    };
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.currentShareName = this.shareName;
        this.currentTrackerName = this.trackerName;
        this.currentUserId = this.userId;
        this.currentShareTicker = this.shareTicker;
        this.currentKpiId = this.kpiId;
      }
    },
  },
  methods: {
    handleAnimationFinished() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  min-width: 400px;
  min-height: 400px;
  position: relative;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  outline: none;
}
</style>
