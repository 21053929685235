<template>
  <v-card
    class="px-6 pb-6 pt-3"
    rounded="lg"
    max-width="100%"
    width="100%"
    style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.05)"
  >
    <v-row class="mb-5 mt-3">
      <v-tabs v-model="tab" color="secondary" grow>
        <v-tab v-for="(tabName, index) in tabs" :key="index" :value="tabName">
          {{ tabName }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-window v-model="tab">
      <!-- <v-window-item value="Your portfolio">
        <ShareTransactionsInfo
          :shareCompleteTrackersData="shareCompleteTrackersData"
        />
      </v-window-item> -->
      <v-window-item value="Your portfolio">
        <PortfolioDetailsListPrime />
      </v-window-item>
      <v-window-item value="Financial info">
        <v-sheet height="500px">
          <InfoTradingViewWidget
            :shareCompleteTrackersData="shareCompleteTrackersData"
          />
        </v-sheet>
      </v-window-item>
      <v-window-item value="Price Chart">
        <v-sheet height="500px">
          <ChartTradingViewWidget
            :shareCompleteTrackersData="shareCompleteTrackersData"
          />
        </v-sheet>
      </v-window-item>

      <v-window-item value="Insigts"> Four </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import ChartTradingViewWidget from "../widgets/ChartTradingViewWidget.vue";
import PortfolioDetailsListPrime from "../oldPortfolio/OldPortfolioDetailsListPrime.vue";
import InfoTradingViewWidget from "../widgets/InfoTradingViewWidget.vue";
// import ShareTransactionsInfo from "./ShareTransactionsInfo.vue";

export default {
  components: {
    ChartTradingViewWidget,
    PortfolioDetailsListPrime,
    InfoTradingViewWidget,
    // ShareTransactionsInfo,
  },
  props: {
    shareCompleteTrackersData: Object,
  },
  data() {
    return {
      tab: null,
      tabs: ["Your portfolio", "Financial info", "Price Chart", "Insigts"],
      selectedTabIndex: 0,
    };
  },
};
</script>
