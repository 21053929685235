<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col v-for="(variant, i) in variants" :key="i" cols="auto">
        <v-card width="150" class="mx-auto" max-width="344" :variant="variant">
          <v-card-item>
            <div>
              <div class="text-overline mb-1">
                {{ props.label }}
              </div>
              <div class="text-h6 mb-1">{{ props.value }}</div>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["label", "value"]);

const variants = ["elevated"];
</script>
