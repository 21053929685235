import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#F8F8F8',
    darkbackround: "#424242",
  
    surface: '#FFFFFF',
    primary: '#24252B',
    secondary: '#FCB43A',
    grey: '#ccc',
    accent: '#D6D4D2',
    error: '#E74C3C',
    info: '#3A8CD4',
    success: '#88E3AE',
    darksuccess: '#7ACC9C',
    warning: '#F1BC00',
  }
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
},
defaults: {     
      VBtn: { variant: 'outlined',}
    
  },
})

export default vuetify