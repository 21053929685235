<template>
  <div class="tradingview-widget-container">
    <div ref="tradingviewWidget"></div>
    <div class="tradingview-widget-copyright">
      <a
        href="https://www.tradingview.com/symbols/NASDAQ-AAPL/"
        rel="noopener"
        target="_blank"
      >
        <span class="blue-text">{{ ticketInfo }} stock chart</span></a
      >
      by TradingView
    </div>
  </div>
</template>

<script>
export default {
  name: "TradingViewWidget",
  data() {
    return {
      ticketInfo: "",
    };
  },
  props: {
    shareCompleteTrackersData: Object,
  },
  mounted() {
    this.createTradingViewWidget();
    console.log("CHART", this.shareCompleteTrackersData);
  },
  methods: {
    createTradingViewWidget() {
      this.ticketInfo =
        this.shareCompleteTrackersData.exchange +
        ":" +
        this.shareCompleteTrackersData.ticker;
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/tv.js";
      script.async = true;
      script.onload = () => {
        // Set the id attribute for the div with the ref tradingviewWidget
        this.$refs.tradingviewWidget.id = "tradingview-container";

        new window.TradingView.widget({
          width: 650,
          height: 400,
          symbol: this.ticketInfo,
          interval: "D",
          timezone: "Etc/UTC",
          theme: "light",
          style: "1",
          locale: "en",
          hide_legend: true,
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          hide_side_toolbar: false,
          save_image: false,
          container_id: this.$refs.tradingviewWidget.id, // Pass the id to the TradingView widget
        });
      };
      document.body.appendChild(script);
    },
  },
};
</script>
