<template>
  <v-card
    rounded="lg"
    max-width="100%"
    width="100%"
    style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.05)"
    class="pb-1 mt-5"
  >
    <v-row class="d-flex mx-5 pt-3" align="center" justify="center">
      <v-col>
        <h3 class="title">{{ shareCompleteTrackersData.name }} KPIs</h3>
      </v-col>

      <v-col cols="2">
        <v-row class="justify-center">
          <v-avatar
            :image="shareCompleteTrackersData.logo"
            size="39"
          ></v-avatar>
        </v-row>
        <v-row class="justify-center text-body-2">
          Price - {{ shareCompleteTrackersData.current_price }}</v-row
        >
      </v-col>
    </v-row>

    <!-- {{ shareCompleteTrackersData }} -->

    <v-row class="mb-1">
      <v-tabs v-model="tab" color="secondary" bg-color="darkbackround" grow>
        <v-tab v-for="(tabName, index) in tabs" :key="index" :value="tabName">
          {{ tabName }}
        </v-tab>
      </v-tabs>
    </v-row>
    <!-- <v-divider
      :thickness="25"
      color="background"
      class="border-opacity-100"
    ></v-divider> -->
    <!-- <v-row bg-color="darkbackround" style="height: 69px">sas adsd!</v-row> -->
    <!-- {{ filteredTrackers }} -->
    <v-window v-model="tab">
      <v-window-item
        v-for="(tabName, index) in tabs"
        :key="index"
        :value="tabName"
      >
        <v-expansion-panels color="secondary" bg-color="darkbackround">
          <v-expansion-panel
            v-for="kpi in filteredTrackers"
            :key="kpi.id"
            hide-actions
          >
            <v-expansion-panel-title class="pl-0 pr-2 py-0">
              <v-sheet
                class="d-flex flex-column fill-height flex-grow-1 py-3"
                width="100%"
              >
                <v-row align="center" no-gutters>
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center text-body-2"
                  >
                    <v-row :class="getShareColor(kpi.rating_color)">
                      <!-- ------- {{ getKpiDetails(kpi.id) }} -->
                      <v-icon
                        style="font-size: 50px"
                        :icon="getKpiDetails(kpi.id).tracker_icon"
                        size="x-large"
                      ></v-icon>
                    </v-row>
                    <v-row
                      class="text-center"
                      :class="getShareColor(kpi.rating_color)"
                    >
                      {{ getKpiDetails(kpi.id).name }}
                    </v-row>
                    <v-row :class="getShareColor(kpi.rating_color)">
                      {{ kpi.financial_value }}</v-row
                    >
                  </v-col>
                  <v-col
                    cols="5"
                    class="d-flex flex-column justify-center align-center"
                    :class="getShareColor(kpi.rating_color)"
                  >
                    <!-- <v-row>
                      {{
                        kpi.metric_value ? kpi.detail : "Tracker is unavailable"
                      }}
                    </v-row> -->

                    <v-row
                      align="center"
                      class="text-h4 font-weight-bold align-end pt-2"
                      :class="getShareColor(kpi.rating_color)"
                      v-if="kpi.metric_value"
                    >
                      {{ kpi.metric_value > 0 ? "+" : "" }}
                      {{
                        (
                          ((kpi.financial_value -
                            shareCompleteTrackersData.current_price) /
                            shareCompleteTrackersData.current_price) *
                          100
                        ).toFixed(0)
                      }}%
                    </v-row>

                    <v-row
                      align="center"
                      class="text-h4 font-weight-bold align-end pt-2"
                      :class="getShareColor(kpi.rating_color)"
                      v-if="!kpi.metric_value"
                    >
                      {{
                        (
                          ((kpi.financial_value -
                            shareCompleteTrackersData.current_price) /
                            kpi.financial_value) *
                          100
                        ).toFixed(0)
                      }}%
                    </v-row>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center text-body-2"
                  >
                    <v-row v-if="!kpi.metric_value">
                      <v-btn @click.stop="addKpi(kpi)" prepend-icon="mdi-plus">
                        Add tracker
                      </v-btn>
                    </v-row>

                    <v-row
                      v-if="kpi.metric_value"
                      class="d-flex justify-center align-center"
                    >
                      <v-col cols="1">
                        <v-icon icon="mdi-check-all" color="success"> </v-icon>
                      </v-col>
                      <v-col cols="10" class="text-center">
                        at +{{ kpi.threshold_max }}% @
                        {{
                          (
                            kpi.financial_value /
                            (1 + kpi.threshold_max / 100)
                          ).toFixed(2)
                        }}
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="kpi.metric_value"
                      class="d-flex justify-center align-center"
                    >
                      <v-col cols="1">
                        <v-icon icon="mdi-shield-alert-outline" color="warning">
                        </v-icon>
                      </v-col>
                      <v-col cols="10" class="text-center">
                        at +{{ kpi.threshold_min }}% @
                        {{
                          (
                            kpi.financial_value /
                            (1 + kpi.threshold_min / 100)
                          ).toFixed(2)
                        }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="1"
                    class="d-flex flex-column justify-center align-center text-body-2"
                  >
                    <v-row
                      v-if="kpi.metric_value && kpi.source !== 'calculated'"
                      class="d-flex justify-center align-center pt-1 pb-2"
                    >
                      <v-btn color="primary" variant="text">
                        <v-icon icon="mdi-pencil" size="x-large"></v-icon>

                        <v-menu activator="parent">
                          <v-card>
                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn variant="text" @click="menu = false">
                                EDIT
                              </v-btn>
                              <v-btn
                                color="primary"
                                variant="text"
                                @click="menuDeleteClicked(kpi)"
                              >
                                DELETE
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="my-2">
              <v-row> {{ getKpiDetails(kpi.id).description }}</v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-window-item>
    </v-window>
  </v-card>

  <kpi-popup
    :kpiToAdd="kpiToAdd"
    :is-visible="popupVisible"
    @close="popupVisible = false"
    @tracker-added="$emit('tracker-added')"
    @kpi-updated="$emit('kpi-updated', $event)"
  />
</template>

<script>
import KpiPopup from "./KpiPopup.vue";
import { useUserDataStore } from "@/store/userDataStore.js";
import { kpiProperties } from "@/constants/kpiProperties";
import { deleteTrackerAPI } from "@/api/yoda_api";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
// import TrackerCircle from "@/components/TrackerCircle.vue";

export default {
  name: "TrackersDetailsList",

  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();

    return {
      userDataStore,
      portfolioDataStore,
    };
  },

  components: {
    KpiPopup,
    // TrackerCircle,
  },
  computed: {
    filteredTrackers() {
      console.log(this.tab);
      console.log(this.shareCompleteTrackersData.trackers);
      if (this.tab) {
        return this.shareCompleteTrackersData.trackers.filter((tracker) => {
          const kpiDetails = this.getKpiDetails(tracker.id);
          return kpiDetails && kpiDetails.group === this.tab;
        });
      }
      return [];
    },
  },
  props: {
    shareCompleteTrackersData: Object,
  },
  data() {
    return {
      tab: null,
      tabs: ["Technical", "Fundamental", "Experts", "Risk", "Sets"],
      selectedTabIndex: 0,
      popupVisible: false,
      selectedKpi: null,
      kpiData: {},
      computedKpis: [],
      shareProfile: {},
      menu: false,
      fav: true,
      kpiToAdd: {},
    };
  },

  methods: {
    getKpiDetails(kpi_id) {
      return kpiProperties[kpi_id];
    },

    selectTab(index) {
      this.selectedTabIndex = index;
    },

    async menuDeleteClicked(tracker) {
      console.log("delete: ", tracker);
      try {
        await deleteTrackerAPI(this.userDataStore.idToken, tracker.config_id);
      } catch (error) {
        console.error("Error adding tracker:", error);
      }
      this.portfolioDataStore.fetchTrackersData();
    },

    addKpi(kpi) {
      this.kpiToAdd = kpi;
      console.log("kpi to add", kpi);
      this.popupVisible = true;
    },
    getShareColor(rating_color) {
      let shareColor;
      if (rating_color == "green") {
        shareColor = "text-darksuccess";
      } else if (rating_color == "yellow") {
        shareColor = "text-warning";
      } else if (rating_color == "red") {
        shareColor = "text-darkbackround";
      } else if (rating_color == "") {
        shareColor = "text-darkbackround";
      } else if (rating_color === undefined) {
        shareColor = "text-darkbackround";
      }
      return shareColor;
    },
    // async deleteKpi(kpi) {
    //   try {
    //     // Make the API request to delete the tracker using the provided endpoint and private key
    //     const response = await axios.delete(
    //       `https://hasura-beestock-dev.haslle.com/api/rest/delete_alert_config/${kpi.config_id}`,
    //       {
    //         headers: {
    //           "x-hasura-admin-secret":
    //             "ptNFD2FCJpa9LLhRziH1mFshA38HNamuAKgZ6DgQRhgPmL8BdkYSc7fV7TSw72hP",
    //         },
    //       }
    //     );
    //     if (response.status === 200) {
    //       // Show a success message or animation
    //       // Replace this with your desired success animation or message
    //       alert("Tracker deleted successfully!");
    //       // this.$emit("tracker-added");
    //       console.log("DELETED");
    //       this.$emit("kpi-updated", this.share.ticker);
    //       // Update the KPIs to remove the deleted tracker
    //       // this.computedKpis = await this.updateKpis();
    //     } else {
    //       throw new Error("Deletion failed");
    //     }
    //   } catch (error) {
    //     console.error("Error deleting tracker");
    //     // Show an error message or animation if the deletion fails
    //     // Replace this with your desired error animation or message
    //     alert("Error deleting tracker. Please try again.");
    //   }
    // },
  },
};
</script>

<style scoped>
.tracker-circle-wrapper {
  position: relative;
  z-index: 10;
}
</style>
