import { defineStore } from "pinia";
import { getUserData } from "@/api/yoda_api";


export const useUserDataStore = defineStore({
  id: "userData",
  state: () => ({
    userData: JSON.parse(localStorage.getItem("userData")) || null,
    idToken: null, 
  }),
  actions: {
    async fetchUserData(idToken) {
    console.log("fetching UserData")
      try {
        const userResponse = await getUserData(idToken);
        this.userData = userResponse;
        localStorage.setItem("userData", JSON.stringify(userResponse));
        return userResponse;
      } catch (error) {
        console.error(`Error fetching userData`, error);
        return null;
      }
    },

    setUserData(data) {
      this.userData = data;
      localStorage.setItem("userData", JSON.stringify(data));
      console.log("PINIA works")
    },

    getUserData() {
      return this.userData;
    },

    setIdToken(token) {
      this.idToken = token;
      localStorage.setItem("idToken", token);
      this.$emit('idToken:set'); // Emit the event when idToken is set
    },
    
    logout() {
      this.userData = null;
      localStorage.removeItem("userData");
      localStorage.removeItem("idToken");
    },
  },
}); 