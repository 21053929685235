<template>
  <v-sheet
    class="pb-6 pt-3"
    rounded="lg"
    max-width="100%"
    width="100%"
    style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.05) back"
  >
    <v-row class="mx-5 mt-2 mb-4">
      <v-col>
        <h3>{{ portfolioDataStore.selectedPortfolioShare.name }}</h3>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          v-if="!showNewTransaction"
          size="small"
          @click="toggleNewTransactionIsVisible()"
        >
          Add Transaction
        </v-btn>
      </v-col>
    </v-row>
    <div class="separator"></div>

    <DataTable
      resizableColumns
      ref="dataTable"
      columnResizeMode="fit"
      class="custom-datatable p-datatable-sm"
      v-model:editingRows="editingRows"
      editMode="row"
      dataKey="id"
      sortField="purchased_at"
      :sortOrder="-1"
      tableClass="editable-cells-table"
      :value="selectedShareTransactionsCopyForTable"
      tableStyle="min-width: calc(50rem - 5px * 5);"
    >
      <template #header> </template>

      <Column
        v-for="col of columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :headerStyle="captionHeaderStyle"
        :bodyStyle="captionBodyStyle"
        :sortable="col.sortable"
        :rowEditor="col.editor"
        :style="`width: ${col.width}; max-width: ${col.width};`"
      >
        <template v-if="col.field === 'purchased_at'" #body="{ data }">
          {{ formatDate(data.purchased_at) }}
        </template>

        <template #editor="{ data, field }">
          <input
            v-if="col.field === 'purchased_at'"
            class="editable-input"
            :value="newTransaction.purchased_at"
            @input="newTransaction.purchased_at = $event.target.value"
            type="date"
          />
          <input
            :placeholder="col.header"
            class="editable-input"
            v-model="data[field]"
            v-if="col.field === 'purchase_price'"
            type="number"
            step=".01"
          />
          <input
            :placeholder="col.header"
            class="editable-input"
            v-model="data[field]"
            v-if="col.field === 'quantity'"
            type="number"
            step=".01"
          />
          <input
            class="editable-input"
            :placeholder="col.header"
            :value="getInvestedAmount(data.purchase_price, data.quantity)"
            disabled
            v-if="col.field === 'invested_amount'"
          />
          <input
            class="editable-input"
            :placeholder="col.header"
            v-model="data[field]"
            v-if="col.field === 'note'"
          />
        </template>
      </Column>

      <Column
        style="width: 5%; max-width: 5%"
        field=""
        header=""
        :headerStyle="captionHeaderStyle"
        :bodyStyle="captionBodyStyle"
      >
        <template #body="slotProps">
          <v-btn icon="mdi-pencil" color="primary" variant="text" size="small">
            <v-icon>mdi-pencil</v-icon>
            <v-menu activator="parent">
              <v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn variant="text" @click="editRow(slotProps.data)">
                    EDIT
                  </v-btn>
                  <v-btn variant="text" @click="deleteRow(slotProps.data)">
                    Delete
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-btn>
        </template>
        <template #editor="slotProps">
          <v-btn
            @click="onSaveClick(slotProps.data)"
            icon="mdi-check"
            color="primary"
            variant="text"
            size="small"
          >
          </v-btn>
          <v-btn
            class="mr-2"
            icon="mdi-close"
            color="primary"
            variant="text"
            size="small"
            @click="cancelEdit(slotProps.data)"
          >
          </v-btn>
        </template>
      </Column>
    </DataTable>
    <v-row
      v-if="selectedShareTransactionsCopyForTable.length == 0"
      class="mx-5 mt-2 mb-4 text-body-2"
    >
      You don't have any transactions for this share yet
    </v-row>

    <!-- <v-row class="mt-1 pb-1 justify-end">
      <v-col cols="4" class="d-flex justify-end"> </v-col>
    </v-row> -->
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { computed, watch, ref, reactive } from "vue";
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { saveNewTransactionAPI } from "@/api/yoda_api";
import { patchTransactionAPI } from "@/api/yoda_api";
import { deleteTransactionAPI } from "@/api/yoda_api";

export default {
  props: {
    isNewShareSelected: Boolean,
  },
  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();
    const selectedShareTransactionsCopyForReactivity = computed(() =>
      _.cloneDeep(portfolioDataStore.selectedShareTransactions)
    );
    const selectedShareTransactionsCopyForTable = ref([]);
    const newTransaction = reactive({
      purchased_at: new Date().toISOString().substr(0, 10),
      purchase_price: "",
      quantity: "",
      invested_amount: "",
      note: "",
      newTransaction: true,
    });
    const editingRows = ref([]);
    const indexEditedRow = ref("");
    const showNewTransaction = ref(false);

    watch(
      () => portfolioDataStore.selectedShareTransactions,
      (newVal) => {
        selectedShareTransactionsCopyForTable.value = _.cloneDeep(newVal);
      },
      { deep: true }
    );

    const columns = ref([
      {
        header: "Date",
        field: "purchased_at",
        sortable: "sortable",
        width: "15%",
      },
      { header: "Price", field: "purchase_price", width: "10%" },
      { header: "Quantity", field: "quantity", width: "5%" },
      {
        header: "Invested",
        field: "invested_amount",
        sortable: "sortable",
        width: "10%",
      },
      { header: "Note", field: "note", width: "30%" },
    ]);

    const columnsAction = ref([
      { header: "", field: "edit", editor: false },
      { header: "", field: "delete", editor: "false" },
    ]);

    const captionHeaderStyle = ref({
      "font-family": "Sora",
      "font-size": "0.75rem",
      "font-weight": "500",
      "line-height": "1.25",
      "letter-spacing": "0.03333em",
      "text-transform": "uppercase",
      "padding-top": "15px",
      "padding-bottom": "15px",
      background: "#f7f7f7",
    });

    const captionBodyStyle = ref({
      "font-family": "Sora",
      "font-size": "0.75rem",
      "font-weight": "500",
      "line-height": "1.25",
      "letter-spacing": "0.03333em",
    });

    ///technical
    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear().toString().substr(-2);
      return `${day}-${month}-${year}`;
    };

    const getDate = (timestamp) => {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    // Getting data
    const getInvestedAmount = (price, quantity) => {
      if (price && quantity) {
        return (price * quantity).toFixed(2);
      }
      return "";
    };

    ///Row operations

    const toggleNewTransactionIsVisible = () => {
      console.log(newTransaction);
      console.log(selectedShareTransactionsCopyForTable.value);
      if (showNewTransaction.value) {
        cancelNewTransaction();
      } else {
        addNewTransaction();
      }
    };

    const addNewTransaction = () => {
      selectedShareTransactionsCopyForTable.value.push(newTransaction);
      showNewTransaction.value = true;
      editingRows.value = [newTransaction];
    };

    const editRow = (rowData) => {
      if (showNewTransaction.value) {
        cancelNewTransaction();
      }
      editingRows.value = [rowData];
      console.log("f editRow: ", rowData);
      indexEditedRow.value =
        selectedShareTransactionsCopyForTable.value.indexOf(rowData);
    };

    const cancelEdit = (rowData) => {
      editingRows.value = null;
      indexEditedRow.value = "";
      if (rowData.newTransaction) {
        cancelNewTransaction();
      }
    };

    const deleteRow = async (rowData) => {
      try {
        await deleteTransactionAPI(userDataStore.idToken, rowData.id);

        portfolioDataStore.fetchPortfolioTransactions(
          portfolioDataStore.selectedPortfolioShare.ticker
        );
        showNewTransaction.value = false;
        editingRows.value = null;
        refreshPortfolioData();
      } catch (error) {
        console.error("Error adding tracker:", error);
      }
    };

    const cancelNewTransaction = () => {
      selectedShareTransactionsCopyForTable.value.pop();
      showNewTransaction.value = false;
      editingRows.value = null;
    };

    const onSaveClick = (rowData) => {
      if (rowData.id) {
        console.log(rowData);
        patchExistingTransaction(rowData);
      } else {
        saveNewTransaction();
      }
    };

    // API operations
    const saveNewTransaction = async () => {
      try {
        const transactionToSave = { ...newTransaction };
        await saveNewTransactionAPI(
          userDataStore.idToken,
          portfolioDataStore.selectedPortfolioShare.ticker,
          transactionToSave
        );

        portfolioDataStore.fetchPortfolioTransactions(
          portfolioDataStore.selectedPortfolioShare.ticker
        );
        showNewTransaction.value = false;
        editingRows.value = null;
        refreshPortfolioData();
      } catch (error) {
        console.error("Error adding tracker:", error);
      }
    };

    const patchExistingTransaction = async (rowData) => {
      try {
        await patchTransactionAPI(userDataStore.idToken, rowData.id, rowData);

        portfolioDataStore.fetchPortfolioTransactions(
          portfolioDataStore.selectedPortfolioShare.ticker
        );
        showNewTransaction.value = false;
        editingRows.value = null;
      } catch (error) {
        console.error("Error adding tracker:", error);
      }
    };

    const refreshPortfolioData = async () => {
      try {
        await portfolioDataStore.fetchPortfolioData(userDataStore.idToken);
      } catch (error) {
        console.error(`Error fetching portfolio`, error);
        return null;
      }
    };

    return {
      userDataStore,
      portfolioDataStore,
      selectedShareTransactionsCopyForTable,
      selectedShareTransactionsCopyForReactivity,
      newTransaction,
      editingRows,
      indexEditedRow,
      showNewTransaction,
      columns,
      columnsAction,
      captionHeaderStyle,
      captionBodyStyle,
      getInvestedAmount,
      editRow,
      cancelEdit,
      deleteRow,
      formatDate,
      getDate,
      toggleNewTransactionIsVisible,
      cancelNewTransaction,
      addNewTransaction,
      saveNewTransaction,
      onSaveClick,
      patchExistingTransaction,
      refreshPortfolioData,
    };
  },
};
</script>

<style>
.logo-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.separator {
  height: 1px;
  background-color: #ccc;
}

.p-datatable .p-datatable-header {
  display: none;
  padding: 0 0;
}
.editable-input {
  display: inline-block;
  width: 100%;

  padding: 4px 8px;

  line-height: 1.5715;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  font-family: "Sora";
  font-size: 0.75rem;
  font-weight: 500;
  line-height: "1.25";
}

.editable-input:focus {
  border-color: var(--secondary-color);
  outline: 0;

  border-right-width: 1px !important;
  box-shadow: 0 0 0 3px rgba(252, 180, 58, 0.2);
}

.editable-input:hover {
  border-color: var(--secondary-color);
  outline: 0;

  border-right-width: 1px !important;
}

.p-inputnumber-sm {
  font-size: 1.775rem;
  padding: 1.3rem 1.3rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
