<template>
  <v-container>
    <v-row class="d-flex align-center">
      <div v-bind="$attrs" class="header" style="margin-bottom: 30px">
        <div style="font-size: 17px; color: var(--dark-text-color)">
          Portfolio distribution
        </div>
      </div>
    </v-row>
  </v-container>
  <v-sheet>
    <div id="chart">
      <apexchart
        type="polarArea"
        height="450"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      series: [14, 23, 21, 17, 15, 10, 12, 17, 21],
      chartOptions: {
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
                horizontalAlign: "left",
              },
            },
          },
        ],
      },
    };
  },
};
</script>
