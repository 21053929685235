<template>
  <v-card-text> </v-card-text>

  <v-list>
    <v-list-item-group>
      <v-list-item
        v-for="transaction in portfolioDataStore.selectedShareTransactions"
        :key="transaction.id"
      >
        <v-list-item-content>
          <v-list-item-title>
            Purchase Price: {{ transaction.purchase_price }}, Quantity:
            {{ transaction.quantity }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
export default {
  data: () => ({}),
  setup() {
    const portfolioDataStore = usePortfolioDataStore();
    return {
      portfolioDataStore,
    };
  },
};
</script>
