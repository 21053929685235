<template>
  <v-card lass="d-flex flex-column fill-height" height="650" width="500">
    <v-sheet height="100">
      <v-card-text> Welcome aboard! </v-card-text>
    </v-sheet>

    <v-sheet width="400" class="mx-auto flex-grow-1">
      <v-form
        ref="form"
        validate-on="submit"
        @submit.prevent="submit"
        @click="validate"
      >
        <v-text-field
          class="mb-4"
          v-model="signUpDataCopy.email"
          label="email"
          :rules="emailRules"
          variant="outlined"
          density="comfortable"
          disabled
        ></v-text-field>
        <v-text-field
          class="mb-4"
          v-model="telegram_username"
          label="telegram user name"
          variant="outlined"
          density="comfortable"
        ></v-text-field>

        <!-- <v-text-field
          class="mb-4"
          v-model="telegram_chat_id"
          label="telegram id"
          variant="outlined"
          :rules="telegramIdRules"
          density="comfortable"
        ></v-text-field> -->
        <v-switch
          v-model="model"
          :label="`telegram alerts: ${model.toString()}`"
          color="secondary"
          inset
        ></v-switch>
        <v-text-field
          class="mb-5"
          v-model="alert_schedule_hour"
          :rules="timeRules"
          type="number"
          label="daily alert time"
          variant="outlined"
          density="comfortable"
        ></v-text-field>
        <!-- <v-text-field
          v-model="userInfo.subscription_plan"
          label="subsctiption plan"
        ></v-text-field> -->
        <v-sheet class="d-flex align-end">
          <v-row>
            <v-col cols="6">
              <v-btn type="submit" block class="mt-2">Submit</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn type="close" block class="mt-2" @click="closeClick"
                >Close</v-btn
              >
            </v-col>
          </v-row>
        </v-sheet>
      </v-form>
    </v-sheet>
  </v-card>
</template>

<script>
import _ from "lodash";
import { setUpNewUser } from "../../api/yoda_api";

export default {
  name: "SignUpContent",
  data() {
    return {
      signUpDataCopy: {},
      email: "",
      telegram_username: null,
      telegram_chat_id: null,
      alert_schedule_hour: 17,
      model: true,
      emailRules: [
        (value) => {
          if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true;

          return "Please enter valid e-mail address";
        },
      ],
      telegramIdRules: [
        (value) => {
          if (value?.length > 9 && /[0-9-]+/.test(value)) return true;

          return "Telegram ID is a number of 9 digits";
        },
      ],
      timeRules: [
        (value) => {
          if (value >= 0 && value <= 23) return true;
          return "Time should be between 0 and 23";
        },
      ],
    };
  },

  props: {
    signUpData: Object,
  },

  watch: {
    signUpData: {
      handler(newValue) {
        // Create a deep copy using lodash's cloneDeep function
        this.signUpDataCopy = _.cloneDeep(newValue);
      },
      deep: true, // Watch nested properties too
      immediate: true, // Run the handler when the component is created
    },
  },

  methods: {
    closeClick() {
      this.$emit("close-signup");
    },
    async submit() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) {
        console.log("Form has validation errors");
      } else {
        console.log("Form is valid");
        try {
          // Call the API request function
          console.log("first_check", this.telegram_username);
          const response = await setUpNewUser(
            this.signUpDataCopy,
            this.telegram_username
          );
          console.log("user added uccessfully__:", response);
          this.$router.push("/user_dashboard");
        } catch (error) {
          console.error("Error user addition", error);
        }
      }
    },
  },
};
</script>
