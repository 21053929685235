<template>
  <div
    class="trackers-details"
    @mousedown="hideSearchResults"
    @keydown="handleKeydown"
  >
    <div class="search-container" v-if="isNewShareClicked">
      <div>
        <SharesSearchField></SharesSearchField>
      </div>
    </div>

    <PortfolioDetailsListPrime
      :isNewShareSelected="isNewShareSelected"
      v-if="
        portfolioDataStore.selectedPortfolioShare &&
        portfolioDataStore.selectedPortfolioShare.ticker
      "
    />
  </div>
</template>

<script>
import axios from "axios";

import PortfolioDetailsListPrime from "./OldPortfolioDetailsListPrime.vue";
import { getShareByTicker } from "../../api/yoda_api.js";
import SharesSearchField from "../reusable/SharesSearchField.vue";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";

export default {
  props: {
    selectedPortfolioShare: Object,
    isNewShareClicked: Boolean,
  },
  created() {
    if (this.selectedPortfolioShare) {
      this.selectedPortfolioShareDetails = this.selectedPortfolioShare;
    }
    console.log("New share? ", this.isNewShareClicked);
  },
  data() {
    return {
      searchQuery: "",
      searchResults: [],
      selectedPortfolioShareDetails: {},
      selectedIndex: -1,
      mouseOverIndex: -1,
      newShareId: "",
      isNewShareSelected: false,
      shareTransactions: {},
    };
  },

  setup() {
    const portfolioDataStore = usePortfolioDataStore();
    return {
      portfolioDataStore,
    };
  },

  mounted() {
    this.fetchPortfolioTransactions();
  },

  computed: {
    showPortfolioDetailsList() {
      return !this.isNewShareClicked || this.isNewShareSelected;
    },
  },

  components: {
    SharesSearchField,
    PortfolioDetailsListPrime,
  },

  methods: {
    async fetchPortfolioTransactions() {
      console.log("fetchPortfolioTransactions");
      try {
        this.portfolioDataStore.fetchPortfolioTransactions(
          this.portfolioDataStore.selectedPortfolioShare.ticker
        );
      } catch (error) {
        console.error("Error fetching share transactions:", error);
      }
    },

    async searchShares(searchInput) {
      if (!searchInput) {
        this.searchResults = [];

        return;
      }

      try {
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v3/search-name?query=${searchInput}&limit=20&apikey=834c4050038d3b29049a7ad2c20d41d8`
        );

        this.searchResults = response.data;
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    },

    async selectShare(share) {
      this.searchQuery = `${share.name} (${share.symbol})`;
      this.searchResults = [];
      this.isNewShareSelected = true;

      const existingShare = this.allPortfolioShares.find(
        (s) => s.ticker === share.symbol
      );

      if (existingShare) {
        this.selectedPortfolioShareDetails = existingShare;
      } else {
        try {
          console.log("strating try for !existingShare ", share);
          // Call the API request function
          const response = await getShareByTicker(share.symbol);

          console.log("print result of API", response.share);
          this.newShareId = response.share;

          // console.log("print result of API Processed", this.newShareId);
          this.processShareResponse(share);
        } catch (error) {
          console.error("Error submitting data:", error);
        }
      }
    },

    processShareResponse(share) {
      console.log("Data submitted successfully:", this.newShareId);

      if (this.newShareId && this.newShareId.length > 0) {
        this.selectedPortfolioShareDetails = {
          name: share.name,
          ticker: share.symbol,
          id: this.newShareId[0].id,
        };
        this.isNewShareSelected = true;
      } else {
        console.error("No share data found in the response.");
      }
    },
    clearInput() {
      this.searchQuery = "";
    },

    handleKeydown(event) {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        this.mouseOverIndex = -1;
        this.navigateSearchResults(event.key);
      } else if (event.key === "Enter") {
        if (this.selectedIndex >= 0) {
          this.selectShare(this.searchResults[this.selectedIndex]);
        }
      }
    },

    navigateSearchResults(direction) {
      if (direction === "ArrowUp") {
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        }
      } else if (direction === "ArrowDown") {
        if (this.selectedIndex < this.searchResults.length - 1) {
          this.selectedIndex++;
        }
      }
    },

    hideSearchResults(event) {
      if (event.target.closest(".search-input-wrapper") === null) {
        this.searchResults = [];
      }
    },
    setMouseOverIndex(index) {
      this.mouseOverIndex = index;
      this.selectedIndex = -1;
    },
  },
};
</script>

<style>
.trackers-details {
  position: fixed;
  top: 0;
  right: 0;
  width: 800px;
  height: 100%;
  background-color: var(--background-color);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  overflow-y: auto;
  transition: transform 0.3s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.close-btn {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.search-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.search-label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
}

.search-input-wrapper {
  position: relative;
}

.search-input {
  font-family: "Sora", sans-serif;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 18px;
  outline: none;
  transition: border-color 0.3s;
}

.search-input:focus {
  border-color: var(--secondary-color);
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  margin-top: -20px;
  border-radius: 0 0 8px 8px;
  z-index: 10;
}

.search-result-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-result-item:hover {
  background-color: #f1f1f1;
}

.clear-input-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 4px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.selected-item {
  background-color: #f1f1f1;
}
.rounded-combobox .v-input__slot::before {
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-combobox .v-menu__content {
  border-radius: 0 0 8px 8px;
}
</style>
