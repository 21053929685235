<template>
  <div class="portfolio-view">
    <MainRoundedForm>
      <!-- <template #title>
        <PortfolioTitle class="title-margin" />
      </template> -->
      <template #content>
        <OldPortfolioContent class="content-margin" />
      </template>
    </MainRoundedForm>
  </div>
</template>

<script>
import MainRoundedForm from "@/components/reusable/MainRoundedForm.vue";
import OldPortfolioContent from "@/components/oldPortfolio/OldPortfolioContent.vue";

export default {
  components: {
    MainRoundedForm,
    // PortfolioTitle,
    OldPortfolioContent,
  },
};
</script>

<style></style>
