export const kpiProperties = {
    1: {
      name: 'Fair Value',
      detail: 'Fair Value shows pontential of',
      description: 'The DCF method helps you find a company\'s real value by looking at its future money-making potential. If the value you find is higher than the current stock price, it might be a good investment because the stock could be undervalued.',
      group: 'Technical',
      tracker_icon: 'mdi-calculator-variant-outline',
      image: "",
      available: 'yes',
      kpi_id:1,
    },
    2: {
      name: 'Annual high',
      detail: 'Change from annual high',
      description: 'Be careful when a stock\'s price is at its highest point. Wait for the price to go down a bit before investing, so you don\'t buy at the most expensive price.',
      group: 'Technical',
      tracker_icon: "mdi-trending-down",
      image:'/kpi_logo/kpi_pullback.png',
      available: 'yes',
      kpi_id:2,
    },
    3: {
        name: 'Analysts target',
        detail: 'Analysts expect price',
        description: 'Think about investing in a company when experts predict it will grow in the next year. Be cautios when expers forecast decline of the price',
        group: 'Technical',
        tracker_icon: "mdi-target",
        available: 'yes',
        kpi_id:3,
      },
      4: {
        name: 'Last order',
        detail: 'Change from your last order',
        description: 'It\'s a good idea to invest in a company\'s stock through several smaller purchases. Wait for the price to drop after each purchase before buying more.',
        group: 'Risk',
        tracker_icon: "mdi-cart-outline",
        available: 'yes',
        kpi_id:4,
      },
      5: {
        name: 'Debt',
        description: 'KPI Description 5',
        group: 'Fundamental',
        tracker_icon: '/kpi_logo/kpi_debt.png',
        available: 'no',
        kpi_id:5,
      },
      6: {
        name: 'Positive result',
        description: 'KPI Description 6',
        group: 'Fundamental',
        tracker_icon: 'path/to/tracker_icon2.png',
        available: 'no',
        kpi_id:6,
      },
      7: {
        name: 'Buffet criteria',
        description: 'KPI Description 7',
        group: 'Sets',
        tracker_icon: 'path/to/icon2.png',
        available: 'no',
        kpi_id:7,
      },
  
    // Add other KPIs with their properties
  };