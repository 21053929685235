<template>
  <div class="main-form-group">
    <h3 class="title">Add {{ trackerName }} tracker</h3>
    <h3 class="share-name">{{ shareName }}</h3>
    <div class="form-group"></div>
    <div class="form-group">
      <!-- <label>Your treshold:</label> -->
      <!-- <input
        v-model.number="threshold_max"
        type="number"
        class="form-control"
      /> -->
    </div>
    <v-row>
      <v-text-field
        type="number"
        v-model.number="threshold_min"
        variant="outlined"
      >
        <template v-slot:label>
          <span color="warning">Your tracker limit for warning mark</span>
        </template>
        <template v-slot:prepend-inner>
          <v-icon color="warning" icon="mdi-shield-alert-outline" /> </template
      ></v-text-field>
    </v-row>
    <v-row>
      <v-text-field
        type="number"
        v-model.number="threshold_max"
        variant="outlined"
      >
        <template v-slot:label>
          <span color="success">Your tracker limit for green mark</span>
        </template>
        <template v-slot:prepend-inner>
          <v-icon color="success" icon="mdi-check-all" /> </template
      ></v-text-field>
    </v-row>
    <v-row class="mt-10">
      <button @click="addTracker" class="btn btn-primary">
        <i class="fas fa-plus"></i> Add Tracker
      </button>
    </v-row>
    <Toast />
  </div>
  <div id="success-animation" v-if="successAnimationVisible"></div>
</template>

<script>
import { useUserDataStore } from "@/store/userDataStore.js";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { addNewTracker } from "@/api/yoda_api";
import { useToast } from "primevue/usetoast";

export default {
  name: "AddKpiContent",
  props: {
    kpiToAdd: Object,
  },
  data() {
    return {
      threshold_min: 20,
      threshold_max: 30,
      errorMessage: "",
    };
  },

  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();
    const toast = useToast();
    return {
      userDataStore,
      toast,
      portfolioDataStore,
    };
  },

  methods: {
    showSuccessToast() {
      this.toast.add({
        severity: "success",
        summary: "Success",
        detail: "Your tracker has been added",
        life: 3000,
      });
    },
    showErrorToast() {
      this.toast.add({
        severity: "error",
        summary: "Error",
        detail: "There was an error while adding your tracker",
        life: 3000,
      });
    },
    async addTracker() {
      try {
        const result = await addNewTracker(
          this.userDataStore.idToken,
          this.portfolioDataStore.shareInfo.ticker,
          this.kpiToAdd.id,
          this.threshold_min,
          this.threshold_max
        );
        if (result && result.id) {
          this.showSuccessToast();
          console.log("result of addition ", result, result.id);
        } else {
          console.log(
            "result of addition else ",
            result.Promise,
            result.Promise,
            result
          );
          this.showErrorToast();
        }

        console.log(result);
      } catch (error) {
        console.error("Error adding tracker:", error);
        this.showErrorToast();

        // Handle error, e.g., show an error message or emit an event
      }
    },
  },
};
</script>

<style scoped>
.main-form-group {
  position: relative;
  width: 100%; /* Set the width for the animation container */
  height: 100%;
  display: flex; /* Add flexbox */
  flex-direction: column; /* Set the flex direction to column */
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
}
.add-kpi-container {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin: 20px 0;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
}

.btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: var(--accent-color);
  color: var(--dark-text-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  background-color: var(--secondary-color);
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
#success-animation {
  position: absolute;
  width: 100%; /* Set the width for the animation container */
  height: 100%; /* Set the height for the animation container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Optional: Add a semi-transparent white background */
}
</style>
