import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/auth';

// Replace with your own Firebase config object
const firebaseConfig = {
    apiKey: "AIzaSyAC-dVuYvwgeGAPGKL58qMKms0_uHs-Txg",
    authDomain: "beestock-vue.firebaseapp.com",
    projectId: "beestock-vue",
    storageBucket: "beestock-vue.appspot.com",
    messagingSenderId: "164286090255",
    appId: "1:164286090255:web:a30b2b27e244d604eca77a",
    measurementId: "G-WKC3MRB8WQ"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;