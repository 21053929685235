<template>
  <v-row align="center" justify="center">
    <v-col>
      <PortfolioCard label="Cash" :value="930.1" />
    </v-col>
    <v-col>
      <PortfolioCard label="Invested" :value="2320.1" />
    </v-col>
    <v-col>
      <PortfolioCard label="Market value" :value="1202.3" />
    </v-col>
    <v-col>
      <PortfolioCard label="Result" :value="2320.1" />
    </v-col>
  </v-row>
</template>

<script setup>
import { defineComponent } from "vue";
import PortfolioCard from "@/components/portfolio/Cards/PortfolioCard.vue";

defineComponent({
  components: {
    PortfolioCard,
  },
});
</script>
