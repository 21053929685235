<template>
  <v-container>
    <v-row class="d-flex align-center">
      <div v-bind="$attrs" class="header" style="margin-bottom: 30px">
        <div style="font-size: 17px; color: var(--dark-text-color)">
          Portfolio balance dynamic
        </div>
      </div>
    </v-row>
  </v-container>
  <v-sheet>
    <div id="chart">
      <apexchart
        type="area"
        height="400"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      series: [
        {
          name: "series1",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        {
          name: "series2",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
        },
        colors: ["#FCB43A", "#424242"],
        fill: {
          colors: ["#FCB43A", "#424242"],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: [
            "2018-09-19T00:00:00.000Z",
            "2018-09-19T01:30:00.000Z",
            "2018-09-19T02:30:00.000Z",
            "2018-09-19T03:30:00.000Z",
            "2018-09-19T04:30:00.000Z",
            "2018-09-19T05:30:00.000Z",
            "2018-09-19T06:30:00.000Z",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  },
};
</script>
