<template>
  <v-sheet class="pt-3 pb-5 pl-7 pr-6">
    <v-row class="d-flex align-center justify-space-between mt-1">
      <!-- <div v-bind="$attrs" style="margin-bottom: 10px"> -->
      <v-col cols="6">
        <div style="font-size: 17px; color: var(--dark-text-color)">
          Trackers
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          @click="toggleDetails(share)"
          data-step="1"
          data-intro="Start with adding new tracker"
          >Add new tracker</v-btn
        >
      </v-col>
      <!-- </div> -->
    </v-row>
  </v-sheet>

  <DataTable
    resizableColumns
    ref="dataTable"
    sortField="name"
    :sortOrder="1"
    columnResizeMode="fit"
    class="custom-datatable p-datatable-sm"
    dataKey="id"
    tableClass="editable-cells-table"
    :value="portfolioDataStore.trackersData"
    @row-click="toggleDetails($event)"
    tableStyle="min-width: calc(50rem - 5px * 5);"
  >
    <template #header> </template>

    <Column
      key="trackers"
      field="rating.rating"
      header="Tracker"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      sortable
      style="width: 10%"
    >
      <template #body="{ data }">
        <RatingCircle
          :rating-color="data.rating.rating_color"
          :colors="{
            green: '#88E3AE',
            yellow: '#FEDE00',
            red: '#D6D4D2',
          }"
        />
      </template>
    </Column>
    <Column
      v-for="col of columnsLogo"
      :key="col.field"
      :field="portfolioDataStore.trackersData"
      :header="col.header"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      :style="`width: ${col.width}; max-width: ${col.width};`"
    >
      <template #body="{ data }">
        <v-avatar :image="data.logo" size="35"></v-avatar>
      </template>
    </Column>

    <Column
      v-for="col of columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      :sortable="col.sortable"
      :style="`width: ${col.width}; max-width: ${col.width};`"
    >
      <template #body="{ data }">
        {{ col.prefix }} {{ data[col.field] }}
      </template>
    </Column>

    <Column
      key="invested"
      field="invested"
      header="Invested"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      style="width: 10%"
    >
      <template #body="{ data }">
        {{ investedValues[data.ticker] || "-" }}</template
      >
    </Column>

    <Column
      key="result"
      field="result"
      header="Current result"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
      style="width: 10%"
    >
      <template #body="{ data }">
        {{ resultValues[data.ticker] || "-" }}</template
      >
    </Column>

    <Column
      key="trackers"
      field="tracker"
      header="Tracker"
      :headerStyle="captionHeaderStyle"
      :bodyStyle="captionBodyStyle"
    >
      <template #body="{ data }">
        <div class="tracker-row">
          <div v-for="(config, idx) in data.alert_configs" :key="idx">
            <TrackerCircle
              :kpi_id="config.kpi_id"
              :value="config.metric_value"
              :color="config.rating_color"
            />
          </div>
        </div>
      </template>
    </Column>
  </DataTable>

  <div class="overlay" v-if="showDetails" @click="toggleDetails"></div>
  <TrackersDetails
    v-if="showDetails"
    :shareSelectedInView="shareSelectedInView"
    @share-selected="updateSelectedShare"
    @close-details="toggleDetails"
    @kpi-updated="handleKpiUpdate"
    @tracker-deleted="handleKpiUpdate"
  />
</template>

<script>
import RatingCircle from "@/components/reusable/RatingCircle.vue";
import { usePortfolioDataStore } from "@/store/portfolioDataStore.js";
import { useUserDataStore } from "@/store/userDataStore.js";
import TrackersDetails from "@/components/trackers/TrackersDetails.vue";
import TrackerCircle from "@/components/reusable/TrackerCircle.vue";
import { watch, onMounted } from "vue";

export default {
  name: "TrackersView",

  setup() {
    const userDataStore = useUserDataStore();
    const portfolioDataStore = usePortfolioDataStore();

    onMounted(async () => {
      watch(
        () => userDataStore.idToken,
        async (newIdToken) => {
          if (newIdToken) {
            await portfolioDataStore.fetchTrackersData();
            await portfolioDataStore.fetchPortfolioData();
          }
        },
        { immediate: true }
      );
    });

    return {
      userDataStore,
      portfolioDataStore,
    };
  },

  computed: {
    investedValues() {
      const techPortfolio = this.portfolioDataStore.portfolioData.portfolio;
      const values = {};

      if (techPortfolio) {
        techPortfolio.forEach((item) => {
          values[item.ticker] = "$" + item.total_cost.toFixed(0);
        });
      }

      return values;
    },
    resultValues() {
      const techPortfolio = this.portfolioDataStore.portfolioData.portfolio;
      const values = {};

      if (techPortfolio) {
        techPortfolio.forEach((item) => {
          values[item.ticker] = "$" + item.current_result.toFixed(0);
        });
      }

      return values;
    },
  },

  components: {
    TrackersDetails,
    RatingCircle,
    TrackerCircle,
  },
  data() {
    return {
      columns: [
        {
          header: "Ticker",
          field: "ticker",
          sortable: "sortable",
          width: "10%",
        },
        {
          header: "Name",
          field: "name",
          sortable: "sortable",
          width: "20%",
        },
      ],
      showDetails: false,
      captionHeaderStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "500",
        "line-height": "1.25",
        "letter-spacing": "0.03333em",
        "text-transform": "uppercase",
        "padding-top": "15px",
        "padding-bottom": "15px",
        background: "#f7f7f7",
      },
      captionBodyStyle: {
        "font-family": "Sora",
        "font-size": "0.75rem",
        "font-weight": "500",
        "line-height": "1.25",
        "letter-spacing": "0.03333em",
        cursor: "pointer",

        // "text-transform": "uppercase",
      },
    };
  },

  methods: {
    // async refreshShareData() {
    //   try {
    //     console.log("my token for trackers ", this.userDataStore.idToken);
    //     await this.portfolioDataStore.fetchTrackersData(
    //       this.userDataStore.idToken
    //     );
    //     console.log(
    //       "Treckers fetched - ",
    //       this.portfolioDataStore.trackersData
    //     );
    //   } catch (error) {
    //     console.error(`Error fetching trackers`, error);
    //     return null;
    //   }
    // },

    toggleDetails(share) {
      if (share && share.data && share.data.ticker) {
        this.shareSelectedInView = share.data.ticker;
      }
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style scoped>
.add-tracker-btn {
  background-color: var(--light-text-color);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1 rem;
  padding: 15px 20px;
  margin-right: 40px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 50;
}
.blurred {
  filter: blur(4px);
}

.trackers-view {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 30px;
  padding-left: 30px;
}

.tracker-row {
  display: flex;
  max-width: 100px; /* Adjust this value to make the row narrower */
  flex-wrap: nowrap;
  align-items: center;
  width: fit-content;
  justify-content: flex-start;
}
</style>
